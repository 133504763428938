body {
  font-family: Arial, sans-serif;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.container {
  text-align: left;
  padding: 20px;
  max-width: 500px; /* Begrenze die maximale Breite des Containers */
  background-color: black;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

h1 {
  color: rgb(154,160,166,1);
  font-weight: 500;
}

h2 {
  color: rgb(154,160,166,1);
  font-weight: 500;
}

p {
  color: rgb(154,160,166,1);
}

li {
  color: rgb(154,160,166,1);
}

span {
  color: rgb(154,160,166,1);
  font-size: x-small;
}

a {
  text-decoration: none;
  color: rgb(154,160,166,1);
}

a.custom-underline {
  text-decoration: underline;
}

.error-image {
  width: 100px;
  height: 100px;
  margin: 20px 0;
}

button {
  background-color: black;
  border: none;
  padding: 5px 15px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
}

.btn {
  background-color: rgb(138,190,248,1);
}

.btn1 {
  color: rgb(138,190,248,1);
  border: 1px solid rgb(138,190,248,1);
  position: relative;
}

button:hover {
  background-color: #0056b3;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.details {
  margin-top: 20px;
  background-color: black;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
